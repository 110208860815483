<template>
  <v-tabs
    color="primary"
    next-icon="fa-chevron-right"
    prev-icon="fa-chevron-left"
    centered
    show-arrows
    :icons-and-text="$vuetify.breakpoint.smAndUp"
    v-model="activeTab"
  >
    <v-tab v-for="tab in tabs" :key="tab.id" :href="tab.route">
      <span
        v-if="$vuetify.breakpoint.smAndUp"
        class="mt-2"
        v-text="$tc(tab.name, 2)"
      ></span>
      <v-img class="mx-auto" height="35" width="35" :src="tabIcons[tab.name]" />
      <v-icon :medium="$vuetify.breakpoint.smAndUp" v-text="tab.icon" dark />
    </v-tab>
    <v-tabs-items touchless v-model="activeTab">
      <v-tab-item value="tab-dashboard" :touch="false">
        <orders v-if="activeTab === 'tab-dashboard'" />
      </v-tab-item>
      <v-tab-item value="tab-list" :touch="false">
        <list v-if="activeTab === 'tab-list'" />
      </v-tab-item>
      <v-tab-item value="tab-calendar" :touch="false">
        <calendar v-if="activeTab === 'tab-calendar'" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import Orders from '@/components/dashboard/orders.vue'
import calendar from '@/components/dashboard/calendar.vue'
import list from '../../pages/sale/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'dashboard',
  components: {
    Orders,
    list,
    calendar
  },
  data() {
    return {
      loading: false,
      activeTab: 'tab-dashboard',
      tabs: [
        {
          id: 1,
          name: 'dashboard',
          route: '#tab-dashboard'
        },
        {
          id: 2,
          name: 'list',
          route: '#tab-list'
        },
        {
          id: 3,
          name: 'calendar',
          route: '#tab-calendar'
        }
      ],
      tabIcons: {
        dashboard: require('@/assets/color-icons/dashboard/dashboard.png'),
        list: require('@/assets/color-icons/dashboard/list.png'),
        calendar: require('@/assets/color-icons/dashboard/calendar.png')
      }
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  methods: {
    async getSettingInfo() {
      this.loading = true
      const company = await this.$api.company.show({
        pk: this.company.pk
      })
      this.loading = false
      this.activeTab = company.data.dashboard
    }
  },
  mounted() {
    this.getSettingInfo()
  }
}
</script>
